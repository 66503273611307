<template>
  <div class="px-2 py-5">
    <h1 class="title">Prueba de Control de ASMA</h1>
    <b-message type="is-info">
      Tome la Prueba de Control del Asma (Asthma Control TestTM – ACT) para
      personas de 12 años de edad en adelante.
    </b-message>

    <div class="block">
      <span class="question">
        1. En las últimas <strong>4 semanas</strong> , ¿cuánto tiempo le ha
        impedido su asma hacer todo lo que quería en el trabajo, en la escuela o
        en la casa?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.ACT_P1"
            :native-value="answers.ACT_P1.ACT_P1_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Siempre
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.ACT_P1"
            :native-value="answers.ACT_P1.ACT_P1_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            La mayoría del tiempo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.ACT_P1"
            :native-value="answers.ACT_P1.ACT_P1_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Algo del tiempo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.ACT_P1"
            :native-value="answers.ACT_P1.ACT_P1_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco del tiempo
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.ACT_P1"
            :native-value="answers.ACT_P1.ACT_P1_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nunca
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span class="question">
        2. Durante las últimas <strong>4 semanas</strong>, ¿con qué frecuencia
        le ha faltado aire?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.ACT_P2"
            :native-value="answers.ACT_P2.ACT_P2_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Más de 1 vez al día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.ACT_P2"
            :native-value="answers.ACT_P2.ACT_P2_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1 vez al día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.ACT_P2"
            :native-value="answers.ACT_P2.ACT_P2_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            De 3-6 veces por semana
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.ACT_P2"
            :native-value="answers.ACT_P2.ACT_P2_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1-2 veces por semana
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.ACT_P2"
            :native-value="answers.ACT_P2.ACT_P2_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nunca
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span class="question">
        3. Durante las últimas <strong>4 semanas</strong> , ¿con qué frecuencia
        sus síntomas del <strong>asma</strong> (respiración sibilante o un
        silbido en el pecho, tos, falta de aire, opresión en el pecho o dolor)
        lo/la despertaron durante la noche o más temprano de lo usual en la
        mañana?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.ACT_P3"
            :native-value="answers.ACT_P3.ACT_P3_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            4 o más noches por semana
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.ACT_P3"
            :native-value="answers.ACT_P3.ACT_P3_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2 ó 3 veces por semana
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.ACT_P3"
            :native-value="answers.ACT_P3.ACT_P3_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Una o dos veces
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.ACT_P3"
            :native-value="answers.ACT_P3.ACT_P3_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Una vez por semana
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.ACT_P3"
            :native-value="answers.ACT_P3.ACT_P3_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nunca
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span class="question">
        4. Durante las últimas <strong>4 semanas</strong>, ¿con qué frecuencia
        ha usado su inhalador de rescate o medicamento en nebulizador (como
        albuterol)?
      </span>

      <div class="columns">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.ACT_P4"
            :native-value="answers.ACT_P4.ACT_P4_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3 o más veces por semana
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.ACT_P4"
            :native-value="answers.ACT_P4.ACT_P4_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            2 ó 3 veces por semana
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.ACT_P4"
            :native-value="answers.ACT_P4.ACT_P4_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1 ó 2 veces al día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.ACT_P4"
            :native-value="answers.ACT_P4.ACT_P4_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Una vez por semana o menos
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.ACT_P4"
            :native-value="answers.ACT_P4.ACT_P4_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Nunca
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span class="question">
        5. ¿Cómo evaluaría el control de su <strong>asma</strong> durante las
        <strong>últimas 4 semanas</strong>?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.ACT_P5"
            :native-value="answers.ACT_P5.ACT_P5_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            No controlada, en absoluto
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.ACT_P5"
            :native-value="answers.ACT_P5.ACT_P5_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mal controlada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.ACT_P5"
            :native-value="answers.ACT_P5.ACT_P5_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Algo controlada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.ACT_P5"
            :native-value="answers.ACT_P5.ACT_P5_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Bien controlada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.ACT_P5"
            :native-value="answers.ACT_P5.ACT_P5_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Completamente controlada
          </b-radio>
        </div>
      </div>
    </div>

    <b-message type="is-info">
      <strong>Rango de resultados</strong>
      <br />
      Buen control ≥ 20
      <br />
      Parcialmente controlado 16 – 19
      <br />
      Mal controlado ≤15
    </b-message>

    <div class="block has-text-centered title question">
      El resultado es: {{ score }}
      <span v-if="isIncompleted">(Incompleto)</span>
      <span class="pl-3">{{ textResult }}</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { ACT as questions } from "../../../data/questions";
import { ACT as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answerOne: "",
      answerTwo: "",
      answerThree: "",
      answerFour: "",
      answerFive: "",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "ActList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
      this.answerFive = this.$route.params.temporalData.answerFive;
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return (
        parseInt(this.answerOne || 0) +
          parseInt(this.answerTwo || 0) +
          parseInt(this.answerThree || 0) +
          parseInt(this.answerFour || 0) +
          parseInt(this.answerFive || 0) || 0
      );
    },
    textResult() {
      let text = "Mal controlado";
      if (this.score >= 1 && this.score <= 15) {
        text = "Mal controlado";
      }
      if (this.score >= 16 && this.score <= 19) {
        text = "Parcialmente controlado";
      }
      if (this.score >= 20) {
        text = "Buen control";
      }

      return text;
    },
    isIncompleted() {
      return (
        !this.answerOne ||
        !this.answerTwo ||
        !this.answerThree ||
        !this.answerFour ||
        !this.answerFive
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = "";
      this.answerTwo = "";
      this.answerThree = "";
      this.answerFour = "";
      this.answerFive = "";
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;
      this.restoreData.answerFive = this.answerFive;

      this.cleanForms();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta ACT.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "ACT",
          result: String(this.score),
          appreciation: this.textResult,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.cleanForms();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question2 = null;
      let question3 = null;
      let question4 = null;
      let question5 = null;

      Object.keys(this.answers[questions.ACT_P1]).forEach((key) => {
        if (this.answers[questions.ACT_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.ACT_P1,
            answerCode: key,
            value: this.answerOne,
          };
        }
      });

      Object.keys(this.answers[questions.ACT_P2]).forEach((key) => {
        if (this.answers[questions.ACT_P2][key] == this.answerTwo) {
          question2 = {
            questionCode: questions.ACT_P2,
            answerCode: key,
            value: this.answerTwo,
          };
        }
      });

      Object.keys(this.answers[questions.ACT_P3]).forEach((key) => {
        if (this.answers[questions.ACT_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.ACT_P3,
            answerCode: key,
            value: this.answerThree,
          };
        }
      });

      Object.keys(this.answers[questions.ACT_P4]).forEach((key) => {
        if (this.answers[questions.ACT_P4][key] == this.answerFour) {
          question4 = {
            questionCode: questions.ACT_P4,
            answerCode: key,
            value: this.answerFour,
          };
        }
      });

      Object.keys(this.answers[questions.ACT_P5]).forEach((key) => {
        if (this.answers[questions.ACT_P5][key] == this.answerFive) {
          question5 = {
            questionCode: questions.ACT_P5,
            answerCode: key,
            value: this.answerFive,
          };
        }
      });
      return [question1, question2, question3, question4, question5];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;
      this.answerFive = this.restoreData.answerFive;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "ACT",
          route: "Act",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
          answerFive: this.answerFive,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "ACT_P1") {
          this.answerOne = survey.value;
        }

        if (survey.question.code == "ACT_P2") {
          this.answerTwo = survey.value;
        }

        if (survey.question.code == "ACT_P3") {
          this.answerThree = survey.value;
        }

        if (survey.question.code == "ACT_P4") {
          this.answerFour = survey.value;
        }

        if (survey.question.code == "ACT_P5") {
          this.answerFive = survey.value;
        }
      });
    },
  },
};
</script>
<style scoped></style>
